<!-- Entête de la page -->
<header id="header-contact" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma structure
  </a>
  <h2 id="title-new-contact" class="page-header__title" *ngIf="!membreId && !targetedCreation">Nouveau membre</h2>
  <h2 id="title-contact-target" class="page-header__title" *ngIf="targetedCreation">{{ targetedCreation }}</h2>
  <h2 id="title-contact-name" class="page-header__title" *ngIf="membreId">{{ membre?.identite?.nom }} {{ membre?.identite?.prenom }}</h2>
  <!-- ATTENTION : bouton dupliqué en bas de page (pour mobiles) -->
  <div class="page-header__action"></div>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Message d'information read only -->
  <div class="card--info card--blue card--readonly" *ngIf="!canEditConsortium">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
    {{ projet.inactif ? 'Pour effectuer des modifications sur le projet, vous devez d’abord le réactiver.' : null }}
  </div>

  <form id="contact-form" class="form" [formGroup]="contactForm" (ngSubmit)="submitFormContact()">
    <!-- Identité -->
    <section class="form-step identite">
      <h3 class="form-step__title">Identité</h3>
      <div class="form-step__content">
        <!-- Genre  -->
        <div
          id="input-raison"
          class="ds-input-group genre_selection_option_overwrite"
          [class.ds-input-group--error]="contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched"
        >
          <label for="genre" class="ds-input-group__label">Civilité <lib-asterisk></lib-asterisk></label>
          <ng-container *ngIf="canEditConsortium; else disabledCivility">
            <div class="div_genre_checkboxs">
              <label *ngFor="let genre of genres">
                <input class="btnlike" type="checkbox" (change)="selectGenre(genre)" [checked]="genre.checked" />
                <span
                  [ngClass]="{ genre_invalid: contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched }"
                  class="unselectable"
                  >{{ EnumGenre.toString(genre.valeur) }}</span
                >
              </label>
            </div>
          </ng-container>
          <!-- genre readonly -->
          <ng-template #disabledCivility>
            <div class="div_genre_checkboxs">
              <label>
                <input class="btnlike ds-input-group__input" type="checkbox" [disabled]="true" />
                <span class="unselectable">{{ contactForm.controls['genre'].value }}</span>
              </label>
            </div>
          </ng-template>

          <!-- Icône et message d'erreur -->
          <ng-container id="error-raison" *ngIf="contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched">
            <div class="ds-input-group__feedback invalid_msg_overwrite">Merci de bien vouloir renseigner une civilité valide.</div>
          </ng-container>
        </div>

        <!-- Nom -->
        <div
          id="input-nom"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['nom'].valid && contactForm.controls['nom'].touched"
        >
          <label for="nom" class="ds-input-group__label">Nom <lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="nom"
              name="nom"
              type="text"
              placeholder="Votre nom"
              class="ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="nom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-nom" *ngIf="!contactForm.controls['nom'].valid && contactForm.controls['nom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
          </ng-container>
        </div>

        <!-- Prénom  -->
        <div
          id="input-prenom"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['prenom'].valid && contactForm.controls['prenom'].touched"
        >
          <label for="prenom" class="ds-input-group__label">Prénom <lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="prenom"
              name="prenom"
              type="text"
              placeholder="Votre prénom"
              class="input_prenom ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="prenom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-prenom" *ngIf="!contactForm.controls['prenom'].valid && contactForm.controls['prenom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
          </ng-container>
        </div>

        <!-- Date de naissance  -->
        <div
          id="input-dateNaissance"
          class="ds-input-group ds-input-group--date"
          [class.ds-input-group--error]="contactForm.controls['dateNaissance'].invalid && contactForm.controls['dateNaissance'].touched"
        >
          <label class="ds-input-group__label"> Date de naissance <lib-asterisk></lib-asterisk> </label>
          <span class="ds-input-group__input">
            <input
              matInput
              [matDatepicker]="openDatePicker"
              formControlName="dateNaissance"
              class="ds-input-group__input"
              placeholder="JJ/MM/AAAA"
              size="10"
              maxlength="10"
              (keydown)="onKeyDown($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openDatePicker></mat-datepicker>
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-dateNaissance"
            *ngIf="!contactForm.controls['dateNaissance'].valid && contactForm.controls['dateNaissance'].touched"
          >
            <div class="ds-input-group__feedback feedback_birthdate">Merci de bien vouloir renseigner une date de naissance valide</div>
          </ng-container>
        </div>

        <!-- Role du contact -->
        <div
          id="input-role"
          class="form-step__role"
          [class.ds-input-group--error]="
            (!contactForm.controls['responsableProjet'].valid && contactForm.controls['responsableProjet'].touched) ||
            (contactForm.controls['responsableProjet'].touched && contactForm.controls['responsableProjet'].value.length === 0)
          "
        >
          <div class="ds-input-group__label">Responsable projet</div>
          <div class="select-container">
            <span id="non-option" class="ds-body-2--regular label-l me-2 align-bottom"> Non</span>
            <label for="rp" class="ds-switch ds-switch--m">
              <input type="checkbox" (change)="toggle($event)" id="rp" formControlName="responsableProjet" />
              <span class="slider"></span>
            </label>
            <span id="oui-option" class="ds-body-2--regular label-r ms-2 align-bottom"> Oui</span>
          </div>
        </div>
      </div>
    </section>
    <!-- FIN de Identité -->

    <div class="form-step role">
      <h3 class="form-step__title">Information projet</h3>
      <div class="form-step__content">
        <!-- Nom -->
        <div
          id="input-role"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['roleEquipe'].valid && contactForm.controls['roleEquipe'].touched"
        >
          <label for="roleEquipe" class="ds-input-group__label">Rôle dans l’équipe <lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="roleEquipe"
              name="roleEquipe"
              type="text"
              placeholder="Votre rôle dans l’équipe"
              class="ds-input-group__input"
              maxlength="40"
              formControlName="roleEquipe"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-role" *ngIf="!contactForm.controls['roleEquipe'].valid && contactForm.controls['roleEquipe'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un rôle valide</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-step formation">
      <h3 class="form-step__title">Formation</h3>
      <div class="form-step__content">
        <!-- Nom -->
        <div
          id="input-formation"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['formation'].valid && contactForm.controls['formation'].touched"
        >
          <label for="formation" class="ds-input-group__label">Niveau de formation <lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="formation"
              name="formation"
              type="text"
              placeholder="Votre niveau de formation"
              class="ds-input-group__input"
              maxlength="40"
              formControlName="formation"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-formation" *ngIf="!contactForm.controls['formation'].valid && contactForm.controls['formation'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un niveau de formation valide</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-step curriculum">
      <h3 class="form-step__title">Curriculum vitae</h3>
      <div
        class="card card--list-file"
        [ngClass]="{
          'card--alert': docNonFourni
        }"
        *ngIf="!documentCv"
      >
        <p class="card__title">Ajouter votre CV <lib-asterisk></lib-asterisk></p>

        <div></div>

        <button type="button" class="ds-btn ds-btn--secondary" (click)="files.click()" *ngIf="canEditConsortium">
          Sélectionner un fichier PDF
        </button>
        <div class="input-file">
          <input type="file" id="files" (change)="onSelectDoc($event.target.files)" accept=".pdf" #files />
        </div>
      </div>

      <div class="card card--list-file" *ngIf="documentCv" [ngClass]="{ 'card--warning': isScanedDocument() && !isValidDocument() }">
        <p class="card__title">
          <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
          CV {{ getNomMembreEquipe() }}
        </p>

        <div class="card__text">
          <span class="card__doc--name card--document--name--overwrite">
            {{ documentCv.nom }}
          </span>

          <br />
          Chargé le {{ documentCv.dateCreation | date : 'dd/MM/yyyy' }} {{ getNomCreateurDocument() }}
        </div>
        <div class="card--actions">
          <ng-container>
            <div class="card__action-button-wrapper">
              <button
                type="button"
                class="card__action-button"
                [ngClass]="{
                  'card__action-button--error': isScanedDocument() && !isValidDocument()
                }"
                (click)="downloadDocument()"
                [disabled]="!isValidDocument() || !isScanedDocument()"
              >
                <lib-pxl-icon class="icon" style="height: 23px" icon="icon-action-save-alt" aria-hidden="true"></lib-pxl-icon>
              </button>
              <div class="card__action-button-tooltip" *ngIf="!isScanedDocument()">
                <p>Le test antimalware est en cours.</p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="canEditConsortium">
            <div class="card__action-button-wrapper action-button-delete_overwrite">
              <button class="card__action-button" (click)="onDeleteDocument()">
                <lib-pxl-icon class="icon" icon="icon-action-delete" aria-hidden="true"></lib-pxl-icon>
              </button>
              <div class="card__action-button-tooltip" *ngIf="isScanedDocument() && !isValidDocument()">
                <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div id="error-logo-size" class="file-error-message" style="margin-bottom: 8px" *ngIf="fileTooBig">
        Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
      </div>
      <div id="error-logo-type" class="file-error-message" style="margin-bottom: 8px" *ngIf="fileNotSupported">
        Le type du document sélectionné n'est pas supporté, format accepté ( pdf ), merci de sélectionner un autre fichier.
      </div>
      <div
        id="error-cv"
        class="file-error-message"
        style="margin-bottom: 8px"
        *ngIf="!contactForm.controls['cv'].valid && contactForm.controls['cv'].touched"
      >
        Merci de bien vouloir renseigner un un curriculum vitae
      </div>
    </div>

    <section *ngIf="isReponsableProjet && enqueteRP" class="parcours">
      <!-- Parcours -->
      <div class="form-step">
        <h3 class="form-step__title">Votre parcours</h3>
        <div class="form-step__content">
          <!-- situation professionnelle -->
          <div
            id="input-situation-professionnelle"
            class="ds-input-group"
            [class.ds-input-group--error]="
              contactForm.controls['situationProfessionnelle'].invalid && contactForm.controls['situationProfessionnelle'].touched
            "
          >
            <label for="situationProfessionnelle" class="ds-input-group__label"
              >Votre situation professionnelle et/ou statut <lib-asterisk></lib-asterisk
            ></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="situation-professionnelle" name="situationProfessionnelle" formControlName="situationProfessionnelle">
                <option [ngValue]="null" selected disabled hidden>Sélectionner votre situation professionnelle</option>
                <option *ngFor="let item of situationProType | keyvalue : valueOrder" value="{{ item.value }}">{{ item.value }}</option>
              </select>
            </div>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="contactForm.controls['situationProfessionnelle'].invalid && contactForm.controls['situationProfessionnelle'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une situation professionnelle</div>
            </ng-container>
          </div>
          <!-- Employé du secteur public -->
          <div
            id="input-secteur-public"
            class="ds-input-group"
            *ngIf="contactForm.controls['emploiSecteurPublic']"
            [hidden]="contactForm.controls['situationProfessionnelle'].value !== EMPLOYE_DU_SECTEUR_PUBLIC"
            [class.ds-input-group--error]="
              contactForm.controls['emploiSecteurPublic'].invalid && contactForm.controls['emploiSecteurPublic'].touched
            "
          >
            <label for="emploiSecteurPublic" class="ds-input-group__label"
              >Si vous êtes employé du secteur publique veuillez précisez <lib-asterisk></lib-asterisk
            ></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="emploi-secteur-public" name="emploiSecteurPublic" formControlName="emploiSecteurPublic">
                <option [ngValue]="null" selected disabled hidden>Sélectionner votre catégorie</option>
                <option *ngFor="let item of secteurPublicType | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
              </select>
            </div>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="contactForm.controls['emploiSecteurPublic'].invalid && contactForm.controls['emploiSecteurPublic'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une catégorie</div>
            </ng-container>
          </div>
          <!-- Organisme -->
          <div
            id="input-organisme-recherche"
            class="ds-input-group"
            *ngIf="contactForm.controls['organismeRecherche']"
            [hidden]="
              contactForm.controls['emploiSecteurPublic'].value !== CHERCHEUR &&
              contactForm.controls['emploiSecteurPublic'].value !== ENSEIGNANT_CHERCHEUR
            "
            [class.ds-input-group--error]="
              !contactForm.controls['organismeRecherche'].valid && contactForm.controls['organismeRecherche'].touched
            "
          >
            <label for="nom" class="ds-input-group__label"
              >Si vous êtes chercheur ou enseignant-chercheur, précisez votre organisme <lib-asterisk></lib-asterisk
            ></label>
            <span class="ds-input-group__input">
              <input
                id="organisme-recherche"
                name="organismeRecherche"
                type="text"
                placeholder="Votre organisme"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="organismeRecherche"
              />
            </span>
            <ng-container *ngIf="contactForm.controls['organismeRecherche'].invalid && contactForm.controls['organismeRecherche'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un organisme</div>
            </ng-container>
          </div>
          <!-- Financement public -->
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['financementsPublic']"
            [hidden]="contactForm.controls['situationProfessionnelle'].value !== ETUDIANT_DOCTORANT"
            [class.ds-input-group--error]="
              contactForm.controls['financementsPublic'].invalid && contactForm.controls['financementsPublic'].touched
            "
          >
            <div class="title-container">
              <div>
                <label class="me-3"
                  >Si vous êtes étudiant doctorant, bénéficiez-vous de financements publics ? <lib-asterisk></lib-asterisk
                ></label>
              </div>
              <div>
                <label class="ds-radio-classic" for="false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="false"
                    name="financementsPublic"
                    formControlName="financementsPublic"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="true"
                    name="financementsPublic"
                    formControlName="financementsPublic"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container *ngIf="contactForm.controls['financementsPublic'].invalid && contactForm.controls['financementsPublic'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
          <!-- Type Financement public -->
          <div
            id="input-secteur-public"
            class="ds-input-group"
            *ngIf="contactForm.controls['typeFinancementsPublic']"
            [hidden]="!contactForm.controls['financementsPublic'].value"
            [class.ds-input-group--error]="
              contactForm.controls['typeFinancementsPublic'].invalid && contactForm.controls['typeFinancementsPublic'].touched
            "
          >
            <label for="typeFinancementsPublic" class="ds-input-group__label">Si oui, précisez <lib-asterisk></lib-asterisk></label>
            <div class="ds-input-group__select ds-input-group__select--with-icon">
              <select id="type-financements-public" name="typeFinancementsPublic" formControlName="typeFinancementsPublic">
                <option [ngValue]="null" selected disabled hidden>Sélectionner votre type de financement public</option>
                <option *ngFor="let item of financementPublicType | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
              </select>
            </div>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="contactForm.controls['typeFinancementsPublic'].invalid && contactForm.controls['typeFinancementsPublic'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un financement</div>
            </ng-container>
          </div>
          <!-- Autre situation professionnelle -->
          <div
            id="input-autre-situation-professionnelle"
            class="ds-input-group"
            *ngIf="contactForm.controls['autreSituationProfessionnelle']"
            [hidden]="contactForm.controls['situationProfessionnelle'].value !== AUTRE"
            [class.ds-input-group--error]="
              !contactForm.controls['autreSituationProfessionnelle'].valid && contactForm.controls['autreSituationProfessionnelle'].touched
            "
          >
            <label for="nom" class="ds-input-group__label">Si autre, précisez <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="autre-situation-professionnelle"
                name="autreSituationProfessionnelle"
                type="text"
                placeholder="Votre précisions"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="autreSituationProfessionnelle"
              />
            </span>
            <ng-container
              *ngIf="
                contactForm.controls['autreSituationProfessionnelle'].invalid &&
                contactForm.controls['autreSituationProfessionnelle'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir préciser</div>
            </ng-container>
          </div>
          <!-- Employeur actuel -->
          <div id="input-detail-emploi-actuel" class="ds-input-group">
            <label for="nom" class="ds-input-group__label">Employeur actuel (s'il y a lieu)</label>
            <span class="ds-input-group__input">
              <input
                id="detail-emploi-actuel"
                name="detailEmploiActuel"
                type="text"
                placeholder="Raison sociale de votre employeur actuel"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="detailEmploiActuel"
              />
            </span>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="etude">
      <div class="form-step etude">
        <div class="form-step__content">
          <div class="d-flex justify-content-between">
            <!-- Niveau de formation -->
            <div
              id="input-niveau-formation"
              class="ds-input-group custom-width"
              [class.ds-input-group--error]="
                contactForm.controls['niveauformation'].invalid && contactForm.controls['niveauformation'].touched
              "
            >
              <label for="niveauformation" class="ds-input-group__label"
                >Votre niveau de formation, dernier diplôme obtenu <lib-asterisk></lib-asterisk
              ></label>
              <div class="ds-input-group__select ds-input-group__select--with-icon">
                <select id="niveau-formation" name="niveauformation" formControlName="niveauformation">
                  <option [ngValue]="null" selected disabled hidden>Sélectionner votre dernier diplôme obtenu</option>
                  <option *ngFor="let item of niveauFormationType | keyvalue : valueOrder" value="{{ item.key }}">{{ item.value }}</option>
                </select>
              </div>
              <!-- Icône et message d'erreur -->
              <ng-container *ngIf="contactForm.controls['niveauformation'].invalid && contactForm.controls['niveauformation'].touched">
                <div class="ds-input-group__feedback">Merci de bien vouloir renseigner votre niveau de formation</div>
              </ng-container>
            </div>
            <div
              id="input-annee-obtention-diplome"
              class="ds-input-group custom-width"
              *ngIf="contactForm.controls['anneeObtentionDiplome']"
              [class.ds-input-group--error]="
                !contactForm.controls['anneeObtentionDiplome'].valid && contactForm.controls['anneeObtentionDiplome'].touched
              "
            >
              <label for="nom" class="ds-input-group__label">Année </label
              ><span *ngIf="contactForm.controls['niveauformation'].value !== 'NON_DIPLOME'"> <lib-asterisk></lib-asterisk></span>
              <span class="ds-input-group__input pt-2">
                <input
                  id="annee-obtention-diplome"
                  name="anneeObtentionDiplome"
                  type="string"
                  placeholder="AAAA"
                  class="ds-input-group__input"
                  maxlength="{{ maxLenghtAnnee }}"
                  formControlName="anneeObtentionDiplome"
                />
              </span>
              <ng-container
                *ngIf="contactForm.controls['anneeObtentionDiplome'].invalid && contactForm.controls['anneeObtentionDiplome'].touched"
              >
                <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une année d'obtention</div>
              </ng-container>
            </div>
          </div>
          <!-- Détail diplôme -->
          <div
            id="input-detail-diplome"
            class="ds-input-group"
            *ngIf="contactForm.controls['detailDiplome']"
            [hidden]="contactForm.controls['niveauformation'].value !== AUTRE_DIPLOME"
            [class.ds-input-group--error]="!contactForm.controls['detailDiplome'].valid && contactForm.controls['detailDiplome'].touched"
          >
            <label for="nom" class="ds-input-group__label">Si autre, précisez <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="detail-diplome"
                name="detailDiplome"
                type="text"
                placeholder="Préciser votre choix autre"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="detailDiplome"
              />
            </span>
            <ng-container *ngIf="contactForm.controls['detailDiplome'].invalid && contactForm.controls['detailDiplome'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir préciser</div>
            </ng-container>
          </div>
          <!-- Double formation -->
          <div id="input-autre-diplome" class="ds-input-group">
            <label for="nom" class="ds-input-group__label">En cas de double formation, indiquer l'autre diplôme</label>
            <span class="ds-input-group__input">
              <input
                id="autre-diplome"
                name="autreDiplome"
                type="text"
                placeholder="Votre double formation"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="autreDiplome"
              />
            </span>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="editionPrecedente">
      <!-- Edition precedente -->
      <div>
        <div>
          <pxl-enquete-radio-toggle
            (toggled)="helperService.toggleValidation($event)"
            id="deja-candidat"
            [title]="'Avez-vous &eacute;t&eacute; candidat aux &eacute;ditions pr&eacute;c&eacute;dentes ?'"
            [radioToggleGroup]="contactForm.get('dejaCandidat')"
          >
            <div
              [class.ds-input-group--error]="contactForm.controls['dejaCandidat'].invalid && contactForm.controls['dejaCandidat'].touched"
            >
              <div class="form-container" [formGroup]="contactForm.controls['dejaCandidat']">
                <div
                  id="input-deja-candidat"
                  class="ds-input-group custom-width"
                  *ngIf="contactForm.controls['dejaCandidat']"
                  [class.ds-input-group--error]="
                    !contactForm.controls['dejaCandidat'].valid && contactForm.controls['dejaCandidat'].touched
                  "
                >
                  <label for="nom" class="ds-input-group__label mt-3">Si oui, quelle(s) année(s) ? </label>
                  <span class="ds-input-group__input">
                    <input
                      id="annee-depot-candidature"
                      name="anneeDepotCandidature"
                      type="text"
                      pattern="^[0-9,]*$"
                      placeholder="Saisir la ou les années de vos précédentes candidatures (Ex : 2010,2015,...)"
                      class="ds-input-group__input"
                      maxlength="{{ maxLenghtAnnees }}"
                      formControlName="value"
                    />
                  </span>
                </div>
              </div>
              <!-- Icône et message d'erreur -->
              <ng-container
                *ngIf="
                  contactForm.controls['dejaCandidat'].get('value').invalid && contactForm.controls['dejaCandidat'].get('value').touched
                "
              >
                <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
              </ng-container>
            </div>
          </pxl-enquete-radio-toggle>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="pepiteTee">
      <!-- PEPITE TEE -->
      <div>
        <div>
          <pxl-enquete-radio-toggle
            (toggled)="helperService.toggleValidation($event)"
            id="pepite-tee"
            [title]="'Pour ce projet, avez-vous &eacute;t&eacute; laur&eacute;at PEPITE - TEE ?'"
            [radioToggleGroup]="contactForm.get('laureatPEPITETEE')"
          >
            <div
              [class.ds-input-group--error]="
                contactForm.controls['laureatPEPITETEE'].invalid && contactForm.controls['laureatPEPITETEE'].touched
              "
            >
              <div class="form-container" [formGroup]="contactForm.controls['laureatPEPITETEE']">
                <div
                  id="input-laureat-pepitee"
                  class="ds-input-group custom-width"
                  *ngIf="contactForm.controls['laureatPEPITETEE']"
                  [class.ds-input-group--error]="
                    !contactForm.controls['laureatPEPITETEE'].valid && contactForm.controls['laureatPEPITETEE'].touched
                  "
                >
                  <label for="nom" class="ds-input-group__label mt-3">Si oui, quelle année ? </label>
                  <span class="ds-input-group__input">
                    <input
                      id="annee-laureat-emergence-ou-beneficie-bourse"
                      name="anneeLaureatEmergenceOuBeneficieBourse"
                      type="text"
                      pattern="^[0-9]*$"
                      placeholder="AAAA"
                      class="ds-input-group__input"
                      maxlength="{{ maxLenghtAnnee }}"
                      formControlName="value"
                    />
                  </span>
                </div>
              </div>
              <!-- Icône et message d'erreur -->
              <ng-container
                *ngIf="
                  contactForm.controls['laureatPEPITETEE'].get('value').invalid &&
                  contactForm.controls['laureatPEPITETEE'].get('value').touched
                "
              >
                <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
              </ng-container>
            </div>
          </pxl-enquete-radio-toggle>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="form-step laureat">
      <!-- Laureat -->
      <div>
        <div class="form-step__content">
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['laureatEmergenceOuBeneficieBourse']"
            [class.ds-input-group--error]="
              contactForm.controls['laureatEmergenceOuBeneficieBourse'].invalid &&
              contactForm.controls['laureatEmergenceOuBeneficieBourse'].touched
            "
          >
            <div class="title-container">
              <div>
                <label class="toggle-title me-3"
                  >Pour ce projet, avez-vous été lauréat "en émergence" ou bénéficiaire d'une Bourse French Tech Emergence ?
                  <lib-asterisk></lib-asterisk
                ></label>
              </div>
              <div>
                <label class="ds-radio-classic" for="laureat-emergence-ou-beneficie-bourse_false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="laureat-emergence-ou-beneficie-bourse_false"
                    name="laureatEmergenceOuBeneficieBourse"
                    formControlName="laureatEmergenceOuBeneficieBourse"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="laureat-emergence-ou-beneficie-bourse_true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="laureat-emergence-ou-beneficie-bourse_true"
                    name="laureatEmergenceOuBeneficieBourse"
                    formControlName="laureatEmergenceOuBeneficieBourse"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container
              *ngIf="
                contactForm.controls['laureatEmergenceOuBeneficieBourse'].invalid &&
                contactForm.controls['laureatEmergenceOuBeneficieBourse'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
          <!-- Annee Laureat -->
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse']"
            [hidden]="!contactForm.controls['laureatEmergenceOuBeneficieBourse'].value"
            [class.ds-input-group--error]="
              contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'].invalid &&
              contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'].touched
            "
          >
            <label for="anneeLaureatEmergenceOuBeneficieBourse" class="ds-input-group__label"
              >Si oui, quelle année <lib-asterisk></lib-asterisk
            ></label>
            <span class="ds-input-group__input">
              <input
                id="annee-laureat-emergence-ou-beneficie-bourse"
                name="anneeLaureatEmergenceOuBeneficieBourse"
                type="text"
                pattern="^[0-9]*$"
                placeholder="AAAA"
                class="ds-input-group__input"
                maxlength="{{ maxLenghtAnnee }}"
                formControlName="anneeLaureatEmergenceOuBeneficieBourse"
              />
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="
                contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'].invalid &&
                contactForm.controls['anneeLaureatEmergenceOuBeneficieBourse'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une annee</div>
            </ng-container>
          </div>
          <!-- Entreprise créée -->
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['entrepriseCree']"
            [class.ds-input-group--error]="contactForm.controls['entrepriseCree'].invalid && contactForm.controls['entrepriseCree'].touched"
          >
            <div class="title-container">
              <div>
                <label class="toggle-title me-3">Une entreprise a-t-elle été créée ? <lib-asterisk></lib-asterisk></label>
              </div>
              <div>
                <label class="ds-radio-classic" for="entreprise-cree_false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="entreprise-cree_false"
                    name="entrepriseCree"
                    formControlName="entrepriseCree"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="entreprise-cree_true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="entreprise-cree_true"
                    name="entrepriseCree"
                    formControlName="entrepriseCree"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container *ngIf="contactForm.controls['entrepriseCree'].invalid && contactForm.controls['entrepriseCree'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
          <!-- Raison sociale -->
          <div
            id="input-raison-sociale-entreprise-cree"
            class="ds-input-group"
            *ngIf="contactForm.controls['raisonSocialeEntrepriseCree']"
            [hidden]="!contactForm.controls['entrepriseCree'].value"
            [class.ds-input-group--error]="
              !contactForm.controls['raisonSocialeEntrepriseCree'].valid && contactForm.controls['raisonSocialeEntrepriseCree'].touched
            "
          >
            <label for="nom" class="ds-input-group__label">Veuillez indiquer sa raison sociale <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="organisme-recherche"
                name="raisonSocialeEntrepriseCree"
                type="text"
                placeholder="Raison sociale"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="raisonSocialeEntrepriseCree"
              />
            </span>
            <ng-container
              *ngIf="
                contactForm.controls['raisonSocialeEntrepriseCree'].invalid && contactForm.controls['raisonSocialeEntrepriseCree'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner la raison sociale</div>
            </ng-container>
          </div>
          <!-- Date de création-->
          <div
            id="input-date-creation-entreprise-cree"
            class="ds-input-group ds-input-group--date"
            *ngIf="contactForm.controls['dateCreationEntrepriseCree']"
            [hidden]="!contactForm.controls['entrepriseCree'].value"
            [class.ds-input-group--error]="
              contactForm.controls['dateCreationEntrepriseCree'].invalid && contactForm.controls['dateCreationEntrepriseCree'].touched
            "
          >
            <label class="ds-input-group__label"> Veuillez indiquer sa date de création <lib-asterisk></lib-asterisk> </label>
            <span class="ds-input-group__input">
              <input
                matInput
                [matDatepicker]="openDatePicker"
                formControlName="dateCreationEntrepriseCree"
                class="ds-input-group__input"
                placeholder="JJ/MM/AAAA"
                size="10"
                maxlength="10"
                (keydown)="onKeyDown($event)"
              />
              <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #openDatePicker></mat-datepicker>
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="
                !contactForm.controls['dateCreationEntrepriseCree'].valid && contactForm.controls['dateCreationEntrepriseCree'].touched
              "
            >
              <div class="ds-input-group__feedback feedback_birthdate">Merci de bien vouloir renseigner une date de création valide</div>
            </ng-container>
          </div>
          <!-- SIREN -->
          <div
            id="input-siren-entreprise-cree"
            class="ds-input-group"
            *ngIf="contactForm.controls['sirenEntrepriseCree']"
            [hidden]="!contactForm.controls['entrepriseCree'].value"
            [class.ds-input-group--error]="
              !contactForm.controls['sirenEntrepriseCree'].valid && contactForm.controls['sirenEntrepriseCree'].touched
            "
          >
            <label for="nom" class="ds-input-group__label">Veuillez indiquer son SIREN <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="siren-entreprise-cree"
                name="sirenEntrepriseCree"
                type="text"
                placeholder="000000000"
                class="ds-input-group__input"
                maxlength="{{ maxLengthSiren }}"
                pattern="^(\d{9}|\d{3}[ ]\d{3}[ ]\d{3})$"
                formControlName="sirenEntrepriseCree"
              />
            </span>
            <ng-container
              *ngIf="contactForm.controls['sirenEntrepriseCree'].invalid && contactForm.controls['sirenEntrepriseCree'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le SIREN</div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="emergence">
      <!-- Laureat en émergence-->
      <div>
        <div>
          <pxl-enquete-radio-toggle
            (toggled)="helperService.toggleValidation($event)"
            id="emergence"
            [title]="'Pour un autre projet, avez-vous &eacute;t&eacute; laur&eacute;at en émergence ?'"
            [radioToggleGroup]="contactForm.get('laureatEmergenceAutreProjet')"
          >
            <div
              [class.ds-input-group--error]="
                contactForm.controls['laureatEmergenceAutreProjet'].invalid && contactForm.controls['laureatEmergenceAutreProjet'].touched
              "
            >
              <div class="form-container" [formGroup]="contactForm.controls['laureatEmergenceAutreProjet']">
                <div
                  id="input-laureat-emergence-autre-projet"
                  class="ds-input-group custom-width"
                  *ngIf="contactForm.controls['laureatEmergenceAutreProjet']"
                  [class.ds-input-group--error]="
                    !contactForm.controls['laureatEmergenceAutreProjet'].valid &&
                    contactForm.controls['laureatEmergenceAutreProjet'].touched
                  "
                >
                  <label for="nom" class="ds-input-group__label mt-3">Si oui, quelle année ? </label>
                  <span class="ds-input-group__input">
                    <input
                      id="annee-laureat-emergence-autre-projet"
                      name="anneeLaureatEmergenceAutreProjet"
                      type="text"
                      pattern="^[0-9]*$"
                      placeholder="AAAA"
                      class="ds-input-group__input"
                      maxlength="{{ maxLenghtAnnee }}"
                      formControlName="value"
                    />
                  </span>
                </div>
              </div>
              <!-- Icône et message d'erreur -->
              <ng-container
                *ngIf="
                  contactForm.controls['laureatEmergenceAutreProjet'].get('value').invalid &&
                  contactForm.controls['laureatEmergenceAutreProjet'].get('value').touched
                "
              >
                <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
              </ng-container>
            </div>
          </pxl-enquete-radio-toggle>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP" class="creation">
      <!-- Laureat creation -->
      <div class="form-step">
        <div class="form-step__content">
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['laureatCreationDev']"
            [class.ds-input-group--error]="
              contactForm.controls['laureatCreationDev'].invalid && contactForm.controls['laureatCreationDev'].touched
            "
          >
            <div class="title-container">
              <div>
                <label class="toggle-title me-3"
                  >Pour un autre projet, avez-vous été lauréat "en Création-développement" <lib-asterisk></lib-asterisk
                ></label>
              </div>
              <div>
                <label class="ds-radio-classic" for="laureat-creation-dev_false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="laureat-creation-dev_false"
                    name="laureatCreationDev"
                    formControlName="laureatCreationDev"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="laureat-creation-dev_true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="laureat-creation-dev_true"
                    name="laureatCreationDev"
                    formControlName="laureatCreationDev"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container *ngIf="contactForm.controls['laureatCreationDev'].invalid && contactForm.controls['laureatCreationDev'].touched">
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
          <!-- Annee Laureat -->
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['anneeLaureatCreationDev']"
            [hidden]="!contactForm.controls['laureatCreationDev'].value"
            [class.ds-input-group--error]="
              contactForm.controls['anneeLaureatCreationDev'].invalid && contactForm.controls['anneeLaureatCreationDev'].touched
            "
          >
            <label for="anneeLaureatCreationDev" class="ds-input-group__label">Si oui, quelle année <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="annee-laureat-creation-dev"
                name="anneeLaureatCreationDev"
                type="text"
                pattern="^[0-9]*$"
                placeholder="AAAA"
                class="ds-input-group__input"
                maxlength="{{ maxLenghtAnnee }}"
                formControlName="anneeLaureatCreationDev"
              />
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="contactForm.controls['anneeLaureatCreationDev'].invalid && contactForm.controls['anneeLaureatCreationDev'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une annee</div>
            </ng-container>
          </div>
          <!-- Entreprise créée -->
          <div
            class="ds-input-group"
            *ngIf="contactForm.controls['entrepriseCreeAutreProjet']"
            [class.ds-input-group--error]="
              contactForm.controls['entrepriseCreeAutreProjet'].invalid && contactForm.controls['entrepriseCreeAutreProjet'].touched
            "
          >
            <div class="title-container">
              <div>
                <label class="toggle-title me-3">Une entreprise a-t-elle été créée ? <lib-asterisk></lib-asterisk></label>
              </div>
              <div>
                <label class="ds-radio-classic" for="entreprise-cree-autre-projet_false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="entreprise-cree-autre-projet_false"
                    name="entrepriseCreeAutreProjet"
                    formControlName="entrepriseCreeAutreProjet"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="entreprise-cree-autre-projet_true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="entreprise-cree-autre-projet_true"
                    name="entrepriseCreeAutreProjet"
                    formControlName="entrepriseCreeAutreProjet"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container
              *ngIf="contactForm.controls['entrepriseCreeAutreProjet'].invalid && contactForm.controls['entrepriseCreeAutreProjet'].touched"
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
          <!-- Raison sociale -->
          <div
            id="input-raison-sociale-entreprise-cree-autre-projet"
            class="ds-input-group"
            *ngIf="contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet']"
            [hidden]="!contactForm.controls['entrepriseCreeAutreProjet'].value"
            [class.ds-input-group--error]="
              !contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'].valid &&
              contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'].touched
            "
          >
            <label for="nom" class="ds-input-group__label">Veuillez indiquer sa raison sociale <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="organisme-recherche"
                name="raisonSocialeEntrepriseCreeAutreProjet"
                type="text"
                placeholder="Raison sociale"
                class="ds-input-group__input"
                maxlength="{{ maxLengthChampLibre }}"
                formControlName="raisonSocialeEntrepriseCreeAutreProjet"
              />
            </span>
            <ng-container
              *ngIf="
                contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'].invalid &&
                contactForm.controls['raisonSocialeEntrepriseCreeAutreProjet'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner la raison sociale</div>
            </ng-container>
          </div>
          <!-- Date de création-->
          <div
            id="input-date-creation-entreprise-cree-autre-projet"
            class="ds-input-group ds-input-group--date"
            *ngIf="contactForm.controls['dateCreationEntrepriseCreeAutreProjet']"
            [hidden]="!contactForm.controls['entrepriseCreeAutreProjet'].value"
            [class.ds-input-group--error]="
              contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].invalid &&
              contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].touched
            "
          >
            <label class="ds-input-group__label"> Veuillez indiquer sa date de création <lib-asterisk></lib-asterisk> </label>
            <span class="ds-input-group__input">
              <input
                matInput
                [matDatepicker]="openDatePicker"
                formControlName="dateCreationEntrepriseCreeAutreProjet"
                class="ds-input-group__input"
                placeholder="JJ/MM/AAAA"
                size="10"
                maxlength="10"
                (keydown)="onKeyDown($event)"
              />
              <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #openDatePicker></mat-datepicker>
            </span>
            <!-- Icône et message d'erreur -->
            <ng-container
              *ngIf="
                !contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].valid &&
                contactForm.controls['dateCreationEntrepriseCreeAutreProjet'].touched
              "
            >
              <div class="ds-input-group__feedback feedback_birthdate">Merci de bien vouloir renseigner une date de création valide</div>
            </ng-container>
          </div>
          <!-- SIREN -->
          <div
            id="input-siren-entreprise-cree-autre-projet"
            class="ds-input-group"
            *ngIf="contactForm.controls['sirenEntrepriseCreeAutreProjet']"
            [hidden]="!contactForm.controls['entrepriseCreeAutreProjet'].value"
            [class.ds-input-group--error]="
              !contactForm.controls['sirenEntrepriseCreeAutreProjet'].valid &&
              contactForm.controls['sirenEntrepriseCreeAutreProjet'].touched
            "
          >
            <label for="nom" class="ds-input-group__label">Veuillez indiquer son SIREN <lib-asterisk></lib-asterisk></label>
            <span class="ds-input-group__input">
              <input
                id="siren-entreprise-cree-autre-projet"
                name="sirenEntrepriseCreeAutreProjet"
                type="text"
                placeholder="000000000"
                class="ds-input-group__input"
                maxlength="{{ maxLengthSiren }}"
                pattern="^(\d{9}|\d{3}[ ]\d{3}[ ]\d{3})$"
                formControlName="sirenEntrepriseCreeAutreProjet"
              />
            </span>
            <ng-container
              *ngIf="
                contactForm.controls['sirenEntrepriseCreeAutreProjet'].invalid &&
                contactForm.controls['sirenEntrepriseCreeAutreProjet'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le SIREN</div>
            </ng-container>
          </div>
        </div>
      </div>
    </section>

    <section *ngIf="isReponsableProjet && enqueteRP && formNETVA !== null" class="netva section-end">
      <!-- NETVA  -->
      <div class="form-step">
        <div class="form-step__content">
          <div
            class="ds-input-group"
            [class.ds-input-group--error]="
              contactForm.controls['acceptTransmissionConcoursNETVA'].invalid &&
              contactForm.controls['acceptTransmissionConcoursNETVA'].touched
            "
          >
            <div class="title-container">
              <div>
                <label class="toggle-title me-3"
                  >Si vous êtes sélectionné en tant que lauréat du concours d'innovation i-Lab de cette année, souhaitez-vous participer au
                  Programme Start in America (anciennement programme NETVA) édition 2024, organisé par Service pour la Science et la
                  Technologie (SST) de l'Ambassade de France aux Etats-Unis, partenaire de Bpifrance ?<span
                    class="ds-input-group__label-asterisk"
                  >
                    *</span
                  >
                </label>
                <div class="mt-2">
                  (Pour en savoir plus sur le programme, rendez-vous sur
                  <a style="color: #0000ee" href="https://la-synapse.com/nos-programmes/programme-netva/" target="_blank"
                    >Le Programme NETVA - La Synapse (la-synapse.com))</a
                  >
                </div>
                <div class="mt-2">
                  En cochant la case "Oui" ci-dessous, j'accepte que Bpifrance, en tant que responsable de traitement, transmette,
                  conformément aux informations figurant dans la
                  <a (click)="downloadNETVA()" class="text-decoration-underline"
                    >notice de protection des données relative au programme Start in America édition 2024</a
                  >, au Ministère de l'Europe et des Affaires étrangères responsable via l'Ambassade de France aux Etats-Unis responsable de
                  l'organisation de ce programme, les informations contenues dans mon dossier de candidature au concours d'innovation i-Lab.
                  Ces données seront transmises uniquement dans le cas où l'entreprise que je représente est sélectionnée en tant que
                  Lauréate du Concours d'innovation i-Lab, afin que le partenaire susmentionné procède à la sélection des participants au
                  programme Start in America (anciennement programme NETVA) édition 2024.
                </div>
                <div class="mt-2">
                  Ces derniers pourront, sous réserve de la règlementation applicable, m’adresser des communications commerciales relatives
                  à leurs offres et services.
                </div>
              </div>
              <div>
                <label class="ds-radio-classic" for="accept-transmission-concours-netva_false">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="accept-transmission-concours-netva_false"
                    name="acceptTransmissionConcoursNETVA"
                    formControlName="acceptTransmissionConcoursNETVA"
                    [value]="false"
                  />
                  <span class="ds-radio__label"><span>Non</span></span>
                </label>
                <label class="ds-radio-classic" for="accept-transmission-concours-netva_true">
                  <input
                    type="radio"
                    class="ds-radio__input"
                    id="accept-transmission-concours-netva_true"
                    name="acceptTransmissionConcoursNETVA"
                    formControlName="acceptTransmissionConcoursNETVA"
                    [value]="true"
                  />
                  <span class="ds-radio__label"><span>Oui</span></span>
                </label>
              </div>
            </div>
            <ng-container
              *ngIf="
                contactForm.controls['acceptTransmissionConcoursNETVA'].invalid &&
                contactForm.controls['acceptTransmissionConcoursNETVA'].touched
              "
            >
              <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une valeur</div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- FIN Parcours -->
    </section>

    <!-- FIN de Contact -->
    <div id="rgpd" class="form-rgpd">
      <lib-mention-rgpd></lib-mention-rgpd>
    </div>

    <div id="action" class="form-actions" *ngIf="canEditConsortium">
      <button id="cancel-save" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToConsortiumInfo()">Annuler</button>
      <button id="save-contact" type="submit" class="ds-btn ds-btn--primary">
        <span *ngIf="!membreId">Ajouter</span>
        <span *ngIf="membreId">Modifier</span>
      </button>
    </div>
  </form>
</div>
