import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationContactModalComponent } from './invitation-contact-modal.component';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiModule } from '@shared-ui';

@NgModule({
  declarations: [InvitationContactModalComponent],
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, SharedUiModule],
})
export class InvitationContactModalModule {}
