import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypePaiement {
  PRELEVEMENT = 'PRELEVEMENT',
  VIREMENT = 'VIREMENT',
  PAIEMENT_RECURRENT = 'PAIEMENT_RECURRENT',
  PAIEMENT_PONCTUEL = 'PAIEMENT_PONCTUEL',
}

export namespace EnumTypePaiement {
  export function toString(operationBancaire: EnumTypePaiement): string {
    switch (operationBancaire) {
      case EnumTypePaiement.PRELEVEMENT:
        return 'Prélèvement';
      case EnumTypePaiement.VIREMENT:
        return 'Virement';
      case EnumTypePaiement.PAIEMENT_RECURRENT:
        return 'Paiement récurrent / répétitif';
      case EnumTypePaiement.PAIEMENT_PONCTUEL:
        return 'Paiement ponctuel';
    }
  }

  export function all(): EnumTypePaiement[] {
    return enumKeys(EnumTypePaiement) as unknown as EnumTypePaiement[];
  }

  export function paiements(): EnumTypePaiement[] {
    return [EnumTypePaiement.PAIEMENT_PONCTUEL, EnumTypePaiement.PAIEMENT_RECURRENT];
  }
}
